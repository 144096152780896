/* eslint-disable semi */
import React from 'react';
// Strap
import { Button, Card, Row, Col, Modal, ModalBody, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

// Table
// Breadcrumbs
// Toast
import 'toastr/build/toastr.min.css';
// Iotera
import Result from 'iotera-base/result';
import { APPLICATION_ID } from 'Apps';
// Redux
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import FailedAnimation from 'Base/assets/FailedAnimation';
import SuccessAnimation from 'Base/assets/SuccessAnimation';
import LoadingAnimation from 'Base/assets/LoadingAnimation';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ModalNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onClik: false,
    };
  }

  static propTypes = {
    serverless_code: PropTypes.number,
    ts: PropTypes.number,
    planogram_history: PropTypes.object,
    togglePlanogram: PropTypes.func,
    modalPlanogram: PropTypes.bool,
    handleValidDate: PropTypes.func
  };
  static defaultProps = {
    serverless_code: 20,
    modalPlanogram: false,
  };

  render() {
    const { serverless_code, ts, planogram_history, togglePlanogram, handleValidDate, modalPlanogram } = this.props;
    return [
      <Modal
        key="modal"
        size="lg"
        isOpen={modalPlanogram}
        toggle={togglePlanogram}
        fullscreen
      >
        {/* <ModalHeader toggle={this.togglePlanogram} tag="h4"></ModalHeader> */}
        <ModalBody>
          <div className=" text-left">
            {serverless_code == Result.NETWORK_FAILURE ? (
              <FailedAnimation />
            ) : serverless_code == Result.TIMEOUT ? (
              <FailedAnimation />
            ) : serverless_code == -11 ? (
              <FailedAnimation />
            ) : serverless_code == Result.FAILED ? (
              <FailedAnimation />
            ) : serverless_code == Result.INPUT_FAILURE ? (
              <FailedAnimation />
            ) : serverless_code == 0 ? (
              <SuccessAnimation />
            ) : (
              <LoadingAnimation />
            )}
            {serverless_code == 20 ? (
              <h3 className="text-center">
                Sedang memproses data, mohon tunggu sebentar...
              </h3>
            ) : (
              <div>
                <Row>
                  <Col sm="4"></Col>
                  <Col sm="4">
                    <Card className="border-solid border-black">
                      <div className="table-responsive mb-2">
                        <Table className="border-solid border-2 table align-middle table-wrap">
                          <thead>
                            <tr>
                              <th scope="col" className="text-start">
                                <div>
                                  <h5 className="font-size-14">
                                    Planogram ID:
                                  </h5>
                                </div>
                              </th>
                              <th scope="row" className="text-end">
                                <div>
                                  <h5 className="font-size-14">{ts}</h5>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th scope="col" className="text-start">
                                <div>
                                  <h5 className="font-size-14">Update By:</h5>
                                </div>
                              </th>
                              <th scope="row" className="text-end">
                                <div>
                                  <h5 className="font-size-14">
                                    {localStorage.getItem('email')}
                                  </h5>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th scope="col" className="text-start">
                                <div>
                                  <h5 className="font-size-14">Update Time:</h5>
                                </div>
                              </th>
                              <th scope="row" className="text-end">
                                <div>
                                  <h5 className="font-size-14">
                                    {handleValidDate(ts)}
                                  </h5>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th scope="col" className="text-start">
                                <div>
                                  <h5 className="font-size-14">Status:</h5>
                                </div>
                              </th>
                              <th scope="row" className="text-end">
                                <div>
                                  {serverless_code ===
                                  Result.NETWORK_FAILURE ? (
                                    <h5 className="font-size-14">
                                      Proses gagal, mesin sedang offline.
                                    </h5>
                                  ) : serverless_code === Result.TIMEOUT ? (
                                    <h5 className="font-size-14">
                                      Proses gagal, mesin tidak memberikan
                                      respon.
                                    </h5>
                                  ) : serverless_code === -11 ? (
                                    <h5 className="font-size-14">
                                      Proses gagal, mesin sedang sibuk melakukan
                                      transaksi.
                                    </h5>
                                  ) : serverless_code === Result.FAILED ? (
                                    <h5 className="font-size-14">
                                      Proses gagal, mesin tidak merespons atau
                                      sedang offline.
                                    </h5>
                                  ) : serverless_code ===
                                    Result.INPUT_FAILURE ? (
                                    <h5 className="font-size-14">
                                      Proses gagal, format planogram salah.
                                      Silakan hubungi Tim Iotera.
                                    </h5>
                                  ) : serverless_code === Result.SUCCESS ? (
                                    <h5 className="font-size-14">
                                      Proses Selesai, mesin telah di update
                                      dengan planogram baru.
                                    </h5>
                                  ) : (
                                    <h5 className="font-size-14">
                                      Sedang memproses data, mohon tunggu
                                      sebentar...
                                    </h5>
                                  )}
                                </div>
                              </th>
                            </tr>
                            {serverless_code === Result.SUCCESS ? (
                              <tr>
                                <th scope="col" className="text-start">
                                  <div>
                                    <h5 className="font-size-14">
                                      Planogram data
                                    </h5>
                                  </div>
                                </th>
                                <th scope="row" className="text-end">
                                  <div className="clearfix mt-4 mt-lg-0">
                                    <ExcelFile
                                      filename={
                                        name + '_' + handleValidDate(ts)
                                      }
                                      element={
                                        <button
                                          type="button"
                                          className="btn btn-primary w-sm"
                                        >
                                          <i className="mdi mdi-download d-block font-size-16"></i>
                                          Download
                                        </button>
                                      }
                                    >
                                      {APPLICATION_ID == '1000000211' ||
                                      APPLICATION_ID == '1000000226' ? (
                                        <ExcelSheet
                                          data={planogram_history}
                                          name="Planogram History"
                                        >
                                          <ExcelColumn
                                            label="selection"
                                            value="selection"
                                            numFmt="0"
                                          />
                                          <ExcelColumn
                                            label="sku"
                                            value="sku"
                                          />
                                          <ExcelColumn
                                            label="name"
                                            value="name"
                                          />
                                          <ExcelColumn
                                            label="price"
                                            value="price"
                                            numFmt="0"
                                          />
                                        </ExcelSheet>
                                      ) : APPLICATION_ID == '1000000258' ? (
                                        <ExcelSheet
                                          data={planogram_history}
                                          name="Planogram History"
                                        >
                                          <ExcelColumn
                                            label="pulse"
                                            value="pulse"
                                            numFmt="0"
                                          />
                                          <ExcelColumn
                                            label="duration"
                                            value="duration"
                                          />
                                          <ExcelColumn
                                            label="price"
                                            value="price"
                                            numFmt="0"
                                          />
                                        </ExcelSheet>
                                      ) : (
                                        <ExcelSheet
                                          data={planogram_history}
                                          name="Planogram History"
                                        >
                                          <ExcelColumn
                                            label="Selection"
                                            value="key"
                                          />
                                          <ExcelColumn
                                            label="Product"
                                            value="name"
                                          />
                                          <ExcelColumn
                                            label="Product SKU"
                                            value="product"
                                          />
                                          <ExcelColumn
                                            label="Product Price"
                                            value="price"
                                            numFmt="0"
                                          />
                                          <ExcelColumn
                                            label="Stock"
                                            value="stock"
                                          />
                                          <ExcelColumn
                                            label="Active"
                                            value="active"
                                          />
                                          <ExcelColumn
                                            label="Column"
                                            value="column"
                                          />
                                        </ExcelSheet>
                                      )}
                                    </ExcelFile>
                                  </div>
                                </th>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </thead>
                        </Table>
                      </div>
                    </Card>
                  </Col>
                  <Col sm="4"></Col>
                </Row>
              </div>
            )}
          </div>
          <div className="text-center mt-4">
            {serverless_code == Result.SUCCESS ? (
              <Link
                to={'/business-point/history/planogram'}
                style={{
                  color: 'white',
                }}
                className="mb-2 btn btn-primary"
              >
                Next
              </Link>
            ) : serverless_code == 20 ? (
              <></>
            ) : (
              <Button color="primary" onClick={togglePlanogram}>
                Close
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>,
    ];
  }
}

export default ModalNotification;
