/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Pages403 from '../pages-403';
const navbar = NAVBAR;
const permision = PERMISION;
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap';

// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Select
import Select from 'react-select';

// Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Redux
import { connect } from 'react-redux';
import {
  initCreate,
  create,
  readInfo,
  updateInfo,
  getSeriVM,
} from '../../store/business-point/actions';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { list_vm } from '../../store/landlord/actions';
import { list_tags } from '../../store/tag/actions';
import { list_distributor } from '../../store/distributor/actions';
import {
  APPLICATION_ID,
  NAME_PROJECT,
  NAVBAR,
  PERMISION,
  buildTitle,
} from 'Apps';
import VMComponent from 'Apps/vmOptions';
let typeOptions = [
  { label: 'Arcade', value: 'arcade'},
  { label: 'Alegria 850', value: 'alegria_850'},
  { label: 'Big Nagomi', value: 'bignagomi' },
  { label: 'Bluemart', value: 'bluemart' },
  { label: 'Bluemart 2105', value: 'bluemart_2105' },
  { label: 'Bluemart 2106', value: 'bluemart_2106' },
  { label: 'Bluemart 2106 HPP', value: 'bluemart_2106_hpp' },
  { label: 'Bluemart 2106 New', value: 'bluemart_2106_new' },
  { label: 'Bluemart 5508', value: 'bluemart_5508' },
  { label: 'Franke', value: 'franke' },
  { label: 'Gea', value: 'gea' },
  { label: 'Gea Slave', value: 'gea_slave' },
  { label: 'iPilot', value: 'ipilot' },
  { label: 'Kubota', value: 'kubota' },
  { label: 'Le_Vending', value: 'levending' },
  { label: 'Massage Chair', value: 'mcpro' },
  { label: 'Massage Chair V2', value: 'mcprov2' },
  { label: 'Massage Chair V3  ', value: 'mcprov3' },
  { label: 'Milano', value: 'milano' },
  { label: 'Nagomi', value: 'nagomi' },
  { label: 'Reyeah', value: 'reyeah' },
  { label: 'Slant', value: 'slant' },
  { label: 'Water Dispenser', value: 'water_dispenser' },
  { label: 'XY', value: 'xy' },
  { label: 'XY Multi Product', value: 'xy_multi' },
];

let typeShareOptions = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Share Revenue', value: 'share_revenue' },
];
class BusinessPointInfo extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let deviceId = null;

    const path = this.props.match.path;
    if (path.includes('/info/')) {
      isEdit = true;
      deviceId = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      selectedTags: { label: 'No Tags', value: '' },
      isEdit,
      selectedType: { label: 'Kubota', value: 'kubota' },
      deviceId,
      loading: false,
      serialNumber: '0000',
      selectedTypeShare: { label: 'Fixed', value: 'fixed' },
    };

    this.handleSelectVM = this.handleSelectVM.bind(this);
    this.handleSelectTags = this.handleSelectTags.bind(this);
    this.handleSelectType = this.handleSelectType.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handelChangelandlord = this.handelChangelandlord.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { isEdit } = this.state;
    // Load info
    if (isEdit) {
      this.props.ReadBusinessPointInfo(this.state.deviceId);
      this.props.Listlandlord();
      this.props.ListDistributor();
    } else {
      this.props.InitBusinessPointInfoCreate();
      this.props.Listlandlord();
      this.props.ListDistributor();
    }
    this.props.GetTagsList();
  }
  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      setTimeout(() => this.setState({ loading: false }), 3000);
    } else {
      this.setState({ loading: false });
    }
  };
  handelChangelandlord(value) {
    const { isEdit } = this.state;
    if (!isEdit) {
      this.setState({ landlord: value.value });
    } else {
      this.setState({ landlord: value.value });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (!isEdit) {
      if (this.props.initCreateResult !== prevProps.initCreateResult) {
        // Insert value of VM select
        const code = safeDeepGet(
          this.props,
          ['initCreateResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const vmOptions = safeDeepGet(
            this.props,
            ['initCreateResult', 'body', 'unregistered_devices'],
            []
          );

          if (vmOptions.length > 0) {
            const selectedVM = vmOptions[0];
            this.setState({ selectedVM });
          }
        }
      }
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code === Result.SUCCESS) {
          this.showToast();
          setTimeout(() => {
            this.props.history.replace('/business-point/list');
          }, 5000);
        } else if (code === Result.FAILED) {
          this.showToastFailed();
        }
      }
    } else {
      if (this.props.readInfoResult !== prevProps.readInfoResult) {
        // Insert value of VM select
        const code = safeDeepGet(
          this.props,
          ['readInfoResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const vmOptions = safeDeepGet(
            this.props,
            ['readInfoResult', 'body', 'registered_devices'],
            []
          );
          let deviceId = null;
          const newState = {};
          {
            APPLICATION_ID == '1000000159' || APPLICATION_ID == '1000000211'
              ? (deviceId = safeDeepGet(this.props, [
                  'readInfoResult',
                  'body',
                  'info',
                  'id',
                ]))
              : (deviceId = safeDeepGet(this.props, [
                  'readInfoResult',
                  'body',
                  'info',
                  'device_id',
                ]));
          }
          const name = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'name',
          ]);
          newState['name'] = name;
          const share_amount = safeDeepGet(
            this.props,
            ['readInfoResult', 'body', 'info', 'fee', 'share_amount'],
            0
          );
          const share_revenue = Number(
            (
              (1 -
                safeDeepGet(
                  this.props,
                  ['readInfoResult', 'body', 'info', 'fee', 'share_amount'],
                  0
                )) *
              100
            ).toFixed(2)
          );

          const type_share = safeDeepGet(
            this.props,
            ['readInfoResult', 'body', 'info', 'fee', 'type'],
            'fixed'
          );
          newState['selectedTypeShare'] = { value: type_share };

          newState['share_amount'] =
            type_share == 'fixed' ? share_amount : share_revenue;
          const type = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'type',
          ]);
          newState['selectedType'] = type;

          const refiller = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'refiller',
          ]);
          newState['refiller'] = refiller;
          const landlord = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'landlord',
          ]);
          newState['landlord'] = landlord;
          const sn = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'sn',
          ]);
          newState['sn'] = sn;
          const pic = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'pic',
          ]);
          newState['pic'] = pic;
          const cp = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'cp',
          ]);
          newState['cp'] = cp;
          const loc = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'loc',
          ]);
          const email = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'email',
          ]);
          newState['email'] = email;
          newState['latitude'] = loc.latitude;
          newState['longitude'] = loc.longitude;
          const address = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'address',
          ]);
          newState['address'] = address;
          const milanosn = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'milanosn',
          ]);
          const date = safeDeepGet(
            this.props,
            ['readInfoResult', 'body', 'info', 'active_date'],
            safeDeepGet(this.props, [
              'readInfoResult',
              'body',
              'info',
              'created_date',
            ])
          );
          const tags = safeDeepGet(
            this.props,
            ['readInfoResult', 'body', 'info', 'tags'],
            '-'
          );
          newState['date'] = new Date(date);
          newState['milanosn'] = milanosn;
          newState['selectedVM'] = deviceId;
          newState['selectedTags'] = tags;
          newState['latestTags'] = tags;
          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      }
      if (this.props.updateInfoResult !== prevProps.updateInfoResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateInfoResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          setTimeout(() => {
            this.props.history.replace('/business-point/list');
          }, 5000);
        } else {
          if (code != Result.UNKNOWN_ERROR) {
            this.showToastFailed();
          }
        }
      }
    }
  }
  handleCancleClick() {
    this.props.history.push(`/business-point/list/`);
  }
  handleSelectVM(value) {
    this.setState({ selectedVM: value });
    if (APPLICATION_ID == '1000000211' || APPLICATION_ID == '1000000159') {
      this.props.GetSeriVm(value.value);
    }
  }
  handleSelectTags(value) {
    // const selectedValue = [];
    // for (const i in value) {
    //   selectedValue.push(value[i]);
    // }
    // this.setState({ selectedTags: selectedValue });
    const { isEdit } = this.state;
    if (isEdit) {
      this.setState({ selectedTags: value.value });
    } else {
      this.setState({ selectedTags: value });
    }
  }
  handleSelectType(value) {
    const { isEdit } = this.state;
    if (isEdit) {
      this.setState({ selectedType: value.value });
    } else {
      this.setState({ selectedType: value });
    }
  }
  showToastFailed() {
    const { isEdit } = this.state;
    var toastType = 'error';
    var message = 'Gagal Tambah Business Point';
    if (isEdit) {
      toastType = 'error';
      message = 'Gagal Update Business Point';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'success';
    var message = 'Berhasil Tambah Business Point';
    if (isEdit) {
      toastType = 'success';
      message = 'Berhasil Update Business Point';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleValidSubmit(event, values) {
    const { isEdit } = this.state;
    const old_value = safeDeepGet(this.props, [
      'readInfoResult',
      'body',
      'info',
    ]);
    const new_value = {
      ...values,
      id: this.state.selectedVM,
      type: this.state.selectedType,
    };
    this.toggleLoader();
    if (isEdit) {
      const submittedValues = {
        ...values,
        id: this.state.selectedVM,
        tags: this.state.selectedTags ?? '',
        latestTags: this.state.latestTags,
        type: this.state.selectedType,
        type_activity: 'edit',
        landlord: this.state.landlord,
        old_value: old_value,
        new_value: new_value,
        // typeShare: this.state.selectedTypeShare.value ,
        // device_id: this.state.deviceId
      };
      // console.log(submittedValues)
      this.props.UpdateBusinessPointInfo(submittedValues);
    } else {
      const submittedValues = {
        ...values,
        id: this.state.selectedVM.value,
        tags: this.state.selectedTags.value ?? '',
        type: this.state.selectedType.value,
        landlord: this.state.landlord,
        type_activity: 'add',
        old_value: {},
        new_value: {},
        // typeShare: this.state.selectedTypeShare.value ,
        // device_id: this.state.deviceId
      };
      // console.log(submittedValues);
      this.props.CreateBusinessPoint(submittedValues);
    }
  }

  handleChange(event) {
    this.setState({ sn: event.target.value });
  }

  handleDate = date => {
    this.setState({ date });
  };
  render() {
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    // TODO Loading
    const vmSeri = safeDeepGet(this.props, [
      'getResult',
      'body',
      '0',
      'serial_number',
    ]);
    const tagsList = safeDeepGet(this.props, ['tagsResult', 'body'], []);
    // console.log(this.props.test)
    const loading = safeDeepGet(this.props, 'loading', true);
    const {
      isEdit,
      selectedVM,
      selectedTags,
      selectedType,
      name,
      sn,
      landlord,
      pic,
      cp,
      email,
      latitude,
      longitude,
      address,
      milanosn,
      share_amount,
      selectedTypeShare,
      date,
    } = this.state;
    let code, vmOptions;
    if (!isEdit) {
      code = safeDeepGet(
        this.props,
        ['initCreateResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      vmOptions = safeDeepGet(
        this.props,
        ['initCreateResult', 'body', 'unregistered_devices'],
        []
      );
    } else {
      code = safeDeepGet(
        this.props,
        ['readInfoResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      vmOptions = safeDeepGet(
        this.props,
        ['readInfoResult', 'body', 'registered_devices'],
        []
      );
    }
    let codesn = '0000';

    if (sn != undefined) {
      const end = sn.length;
      const start = end - 4;
      if (end == 0) {
        codesn = '0000';
      } else if (end == 1) {
        codesn = '000' + sn.substring(start, end);
      } else if (end == 2) {
        codesn = '00' + sn.substring(start, end);
      } else if (end == 3) {
        codesn = '0' + sn.substring(start, end);
      } else {
        codesn = sn.substring(start, end);
      }
    }

    const endapp = APPLICATION_ID.length;
    const startapp = endapp - 4;
    const appSn = APPLICATION_ID.substring(startapp, endapp);

    const vmcode = appSn + '-' + codesn;
    const landlordOptions = safeDeepGet(this.props, ['landlordVm', 'body'], []);
    const distributorOptions = safeDeepGet(
      this.props,
      ['distributorVm', 'body'],
      []
    );
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Business Point')}</title>
          </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Business Point', 'edit'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid={true}>
              <Breadcrumbs
                title="Business Point"
                breadcrumbItem={isEdit ? 'Edit' : 'Add'}
              />
              <AvForm onValidSubmit={this.handleValidSubmit}>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <h4 className="card-title">VM Information</h4>
                        <p className="card-title-desc">
                          Pilih serial number VM di business point ini.
                        </p>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                value={name}
                                name="name"
                                label="Nama Business Point"
                                placeholder="Nama"
                                type="text"
                                errorMessage="Masukkan Nama"
                                className="form-control"
                                validate={{ required: { value: true } }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                value={vmSeri ? vmSeri : sn}
                                name="sn"
                                label="Serial Number Mesin"
                                placeholder="Serial number"
                                type="text"
                                errorMessage="Masukkan Serial Number Dengan Benar"
                                className="form-control"
                                onChange={this.handleChange}
                                validate={{
                                  required: { value: true },
                                  pattern: { value: '^[A-Z0-9]+$' },
                                }}
                                disabled={isEdit}
                              />
                              <p>Kode Mesin ini Adalah {vmcode}</p>
                            </FormGroup>
                          </Col>
                        </Row>

                        <VMComponent
                          isEdit={isEdit}
                          vmOptions={vmOptions}
                          tagsList={tagsList}
                          selectedVM={selectedVM}
                          selectedTags={selectedTags}
                          handleSelectVM={this.handleSelectVM}
                          handleSelectTags={this.handleSelectTags}
                          typeOptions={typeOptions}
                          selectedType={selectedType}
                          handleSelectType={this.handleSelectType}
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">PiC & Location</CardTitle>
                        <p className="card-title-desc">
                          Penanggungjawab dan lokasi VM.
                        </p>
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <AvField
                                value={pic}
                                name="pic"
                                label="Nama PiC"
                                placeholder="Nama"
                                type="text"
                                errorMessage="Masukkan Nama PiC"
                                className="form-control"
                                validate={{ required: { value: true } }}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                value={cp}
                                name="cp"
                                label="Telepon"
                                placeholder="Masukkan Angka saja"
                                type="text"
                                errorMessage="Masukkan Angka saja"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: '^[0-9]+$',
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                value={email}
                                name="email"
                                label="E-mail"
                                placeholder="E-mail"
                                type="text"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={4}>
                            <FormGroup className="mb-3">
                              <AvField
                                value={latitude}
                                name="latitude"
                                label="Latitude"
                                placeholder="Masukkan latitude"
                                type="text"
                                errorMessage="Masukkan latitude (-6.xxx)"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: '/([0-9.-]+).+?([0-9.-]+)/',
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            lg={2}
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              flex: 1,
                              marginTop: '35px',
                            }}
                          >
                            <p>Contoh: -6.xxx</p>
                          </Col>
                          <Col lg={4}>
                            <FormGroup className="mb-3">
                              <AvField
                                value={longitude}
                                name="longitude"
                                label="Longitude"
                                placeholder="Masukkan longitude"
                                type="text"
                                errorMessage="Masukkan longitude (106.xxx)"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: '/([0-9.-]+).+?([0-9.-]+)/',
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            lg={2}
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              flex: 1,
                              marginTop: '35px',
                            }}
                          >
                            <p>Contoh: 106.xxxxx</p>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <AvField
                                value={address}
                                name="address"
                                label="Alamat"
                                placeholder="Masukkan alamat"
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {APPLICATION_ID == '1w' ? (
                      <Card>
                        <CardBody>
                          <CardTitle className="h4">Landlord</CardTitle>
                          <p className="card-title-desc">Landlord pada VM.</p>
                          <Row>
                            <Col md={6}>
                              <FormGroup className="mb-3 select2-container">
                                <Label>Share Revenue Type</Label>
                                <Select
                                  value={typeShareOptions.filter(function (
                                    option
                                  ) {
                                    return (
                                      option.value === selectedTypeShare.value
                                    );
                                  })}
                                  onChange={this.handleTypeChange}
                                  options={typeShareOptions}
                                  classNamePrefix="select2-selection"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              {selectedTypeShare.value == 'share_revenue' ? (
                                <FormGroup className="mb-3">
                                  <AvField
                                    value={share_amount}
                                    name="share_amount"
                                    label={
                                      selectedType.value == 'fixed'
                                        ? 'Sewa Lokasi'
                                        : 'Share Revenue Lokasi (%)'
                                    }
                                    placeholder={
                                      selectedType.value == 'fixed'
                                        ? 'Biaya Sewa Lokasi'
                                        : 'Share Revenue Lokasi (%)'
                                    }
                                    type="number"
                                    errorMessage="Angka dengan Benar"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    validate={{
                                      required: { value: true },
                                      pattern: { value: '^[0-9]+(.[0-9]+)?$' },
                                    }}
                                  />
                                </FormGroup>
                              ) : null}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ) : null}
                    {APPLICATION_ID == 'Fuji' ? (
                      <Card>
                        <CardBody>
                          <CardTitle className="h4">Landlord</CardTitle>
                          <p className="card-title-desc">Landlord pada VM.</p>
                          <Row>
                            <Col lg={12}>
                              <FormGroup className="mb-3">
                                <Label>Landlord</Label>
                                <Select
                                  value={
                                    isEdit
                                      ? landlordOptions.filter(function (
                                          option
                                        ) {
                                          return option.value === landlord;
                                        })
                                      : this.state.landlord
                                  }
                                  onChange={this.handelChangelandlord}
                                  options={landlordOptions}
                                  classNamePrefix="select2-selection"
                                  // isDisabled={isEdit}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ) : null}

                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Button
                        onClick={this.handleCancleClick}
                        color="secondary"
                      >
                        Cancel
                      </Button>{' '}
                      {guest == 'off' ? (
                        <Button
                          type="submit"
                          color="primary"
                          disabled={loading || selectedVM == null}
                        >
                          {this.state.loading ? (
                            <Spinner color="light" size="sm"></Spinner>
                          ) : null}
                          Simpan
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['businessPoint', 'initCreate'], {}),
  readInfoResult: safeDeepGet(state, ['businessPoint', 'readInfo'], {}),
  createResult: safeDeepGet(state, ['businessPoint', 'create'], {}),
  updateInfoResult: safeDeepGet(state, ['businessPoint', 'updateInfo'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  getResult: safeDeepGet(state, ['businessPoint', 'getSeriVM'], {}),
  landlordVm: safeDeepGet(state, ['landlord', 'list_vm'], true),
  distributorVm: safeDeepGet(state, ['distributor', 'list_distributor'], true),
  tagsResult: safeDeepGet(state, ['tag', 'list_tags'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitBusinessPointInfoCreate: () => dispatch(initCreate()),
  ReadBusinessPointInfo: id => dispatch(readInfo(id)),
  Listlandlord: () => dispatch(list_vm()),
  ListDistributor: () => dispatch(list_distributor()),
  CreateBusinessPoint: payload => dispatch(create(payload)),
  GetSeriVm: payload => dispatch(getSeriVM(payload)),
  UpdateBusinessPointInfo: payload => dispatch(updateInfo(payload)),
  GetTagsList: () => dispatch(list_tags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPointInfo);
