/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import makeAnimated from 'react-select/animated';
// Strap
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  CardTitle,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
// Router
// Table
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import {
  create,
  list,
  delete_,
  list_vm,
  download,
  initCreate,
  edit,
  detail,
  set_price_subs,
} from '../../store/business-point-fee/actions';
import { list as listTag } from '../../store/tags/actions';
import { readInfo, update_time } from '../../store/business-point/actions';
// import BusinessPoint from "../../../Component/businessPoint"
const typeOptions = [
  { label: 'Share revenue', value: 'share_revenue' },
  { label: 'Subscription', value: 'subscription' },
];
const categoryOption = [
  { label: 'Baru', value: 'create' },
  { label: 'Perpanjangan', value: 'update_time' },
];
const trxOptions = [
  { label: 'Bruto Transaction', value: 'bruto' },
  { label: 'Nett Transaction', value: 'nett' },
  { label: 'Profit', value: 'profit' },
  { label: 'Total Transaction', value: 'total' },
];
let tagOption = [

];

class BusinessPoint extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const appId = safeDeepGet(this.props.match.params, 'appId');

    this.state = {
      appId,
      type: { label: 'Share revenue', value: 'share_revenue' },
      role: '',
      modalEdit: false,
      modalDate: false,
      modalPrice: false,
      loading: false,
      success_dlg: false,
      error_dlg: false,
      subscription: false,
      rfid: false,
      dynamic_title: '',
      device_id: '',
      trx: '',
      dynamic_description: '',
      confirm_alert: false,
      Columns: [
        {
          text: 'device_id',
          dataField: 'device_id',
          sort: true,
          hidden: true,
        },
        {
          dataField: 'business_point',
          text: 'Business Point',
          sort: true,
          headerAlign: (column, colIndex) => 'center',
          // align: 'center',
          formatter: (cellContent, row) => (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {row.business_point}
              </p>
              <p className="mb-1">{row.device_id}</p>
            </>
          ),
        },
        {
          dataField: 'active_date',
          text: 'Active Date',
          sort: true,
          headerAlign: (column, colIndex) => 'center',
          // align: 'center',
          formatter: (cellContent, row) => (
            <p className="mt-3">{this.handleValidDate(row.active_date)}</p>
          ),
        },
        {
          dataField: 'version',
          text: 'Version',
          headerAlign: (column, colIndex) => 'center',
          // align: 'center',
          formatter: (cellContent, row) => (
            <p className="mt-3">{row.version}</p>
          ),
        },
        {
          dataField: 'appsVersion',
          text: 'Apps Version',
          headerAlign: (column, colIndex) => 'center',
          // align: 'center',
          formatter: (cellContent, row) => {
            <>
              <p className="mb-1" style={{ textAlign: 'left' }}>
                IOT: {safeDeepGet(row, ['appsVersion', 'iot'], '0.0.0')}
              </p>
              <p className="mb-1" style={{ textAlign: 'left' }}>
                Watchdog:{' '}
                {safeDeepGet(row, ['appsVersion', 'watchdog'], '0.0.0')}
              </p>
              <p className="mb-1" style={{ textAlign: 'left' }}>
                Remote: {safeDeepGet(row, ['appsVersion', 'remote'], '0.0.0')}
              </p>
              <p className="mb-1" style={{ textAlign: 'left' }}>
                Applicaton:{' '}
                {safeDeepGet(row, ['appsVersion', 'application'], '0.0.0')}
              </p>
            </>;
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          headerAlign: (column, colIndex) => 'center',
          // align: 'center',
          formatter: (cellContent, row) => <p className="mt-3">{row.type}</p>,
        },
        {
          dataField: 'nameTags',
          text: 'Tags',
          sort: true,
          headerAlign: (column, colIndex) => 'center',
          // align: 'center',
          formatter: (cellContent, row) => (
            <>{row.nameTags != undefined ? row.nameTags : null}</>
          ),
        },
        {
          dataField: 'addOn',
          text: 'Add On',
          headerAlign: (column, colIndex) => 'center',
          align: 'center',
          formatter: (cellContent, row) => (
            <>
              {row.type == 'subscription' ? (
                <>
                  <p className="mb-1" style={{ textAlign: 'right' }}>
                    Start Date: {this.handleValidDate(row.start_date)}
                  </p>
                  <p className="mb-1" style={{ textAlign: 'right' }}>
                    End Date: {this.handleValidDate(row.end_date)}
                  </p>
                </>
              ) : (
                <></>
              )}

              {/* <p className="mb-1" style={{ textAlign: 'right' }}>
                Subscription = Rp.
                {Number(safeDeepGet(row, ['fee', 'daily_subscription'], 0))
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                ,-
              </p>
              <p className="mb-1" style={{ textAlign: 'right' }}>
                RFID = Rp.
                {Number(safeDeepGet(row, ['fee', 'rfid'], 0))
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                ,-
              </p> */}
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, row) => (
            <>
              <div className="d-flex gap-2">
                <Tippy content={'Edit'}>
                  <Link className="text-success" to="#">
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      onClick={() => this.handleEditClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Date'}>
                  <Link className="text-success" to="#">
                    <i
                      className="mdi mdi-clock-time-eight font-size-18"
                      id="Datetooltip"
                      onClick={() => this.handleDateClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                <Tippy content={'Summary'}>
                  <Link className="text-success" to="#">
                    <i
                      className="mdi mdi-microsoft-excel font-size-18"
                      id="edittooltip"
                      onClick={() => this.handleSummaryClick(row)}
                    ></i>
                  </Link>
                </Tippy>
                {row.type == 'share_revenue' ? (
                  <Tippy content={'Graph'}>
                    <Link className="text-info" to="#">
                      <i
                        className="mdi mdi-chart-box font-size-18"
                        id="edittooltip"
                        onClick={() => this.handleGraphClick(row)}
                      ></i>
                    </Link>
                  </Tippy>
                ) : // ) : row.type == 'subscription' ? (
                //   <Tippy content={'Price'}>
                //     <Link className="text-info" to="#">
                //       <i
                //         className="mdi mdi-bank font-size-18"
                //         id="edittooltip"
                //         onClick={() => this.handleChangePrice(row)}
                //       ></i>
                //     </Link>
                //   </Tippy>
                null}
                <Tippy content={'Delete'}>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      // onClick={() => this.handleDeleteClick(row.device_id)}
                      onClick={() =>
                        this.setState({
                          confirm_alert: true,
                          device_id: row.device_id,
                        })
                      }
                    ></i>
                  </Link>
                </Tippy>
              </div>
            </>
          ),
        },
      ],
    };
    this.showToast = this.showToast.bind(this);
    this.showToastFailed = this.showToastFailed.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.togglePrice = this.togglePrice.bind(this);
    this.toggleDate = this.toggleDate.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handelChangeType = this.handelChangeType.bind(this);
    this.handelChangeTag = this.handelChangeTag.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleGraphClick = this.handleGraphClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleValidSubmitDate = this.handleValidSubmitDate.bind(this);
    this.handleValidSubmitPrice = this.handleValidSubmitPrice.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handelChangeBp = this.handelChangeBp.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handelChangeCategory = this.handelChangeCategory.bind(this);
    this.handelChangeTrx = this.handelChangeTrx.bind(this);
    this.handleSummaryClick = this.handleSummaryClick.bind(this);
    this.handleValidEdit = this.handleValidEdit.bind(this);
  }
  handelChangeType(value) {
    this.setState({ type: value.value });
  }

  handleSummaryClick = row => {
    const { appId } = this.state;
    const deviceId = row.device_id;
    // console.log('/admin/summary/' + appId+'/'+deviceId)
    this.props.history.replace('/admin/summary/' + appId + '/' + deviceId);
  };
  handleValidDate(value) {
    const date = new Date(value).toLocaleDateString();
  }
  handelChangeBp(value) {
    this.setState({ bp: value });
    // this.setState({ device_id: value.value, label: value.label });
  }
  handelChangeTag(value) {
    this.setState({ tag: value.value });
  }
  handelChangeCategory(value) {
    this.setState({ category: value.value });
  }
  handelChangeTrx(value) {
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const device_idMap = [];
    const nameMap = [];
    for (const i in list) {
      const deviceid = safeDeepGet(list[i], 'device_id', '');
      device_idMap.push(deviceid);
      const name = safeDeepGet(list[i], 'business_point', '');
      nameMap.push(name);
    }

    const payload = {
      device: device_idMap,
      businessPoint: nameMap,
      type: value.value,
    };
    this.props.Download(payload);
    this.setState({ trx: value.value, labeltrx: value.label });
  }

  componentDidMount() {
    // Load business point
    this.props.LoadBusinessPointList(this.state.appId);
    this.props.LoadBusinessPointVM(this.state.appId);
    this.props.InitCreate(this.state.appId);
    this.props.ReadInfoBp(this.state.appId);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      this.setState({ loading: false });
      if (code === Result.SUCCESS) {
        this.showToast('Success');
        setTimeout(
          () => this.props.LoadBusinessPointList(this.state.appId),
          500
        );
        this.props.LoadBusinessPointList(this.state.appId);
        if (this.state.isEdit) {
          this.toggleEdit();
        } else {
          this.toggle();
        }
      } else if (code === Result.FAILED) {
        this.showToastFailed('Failed');
        // this.toggle();
      }
    }
    if (this.props.editResult !== prevProps.editResult) {
      const code = safeDeepGet(
        this.props,
        ['editResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      this.setState({ loading: false });
      if (code === Result.SUCCESS) {
        this.showToast('Success');
        setTimeout(
          () => this.props.LoadBusinessPointList(this.state.appId),
          2000
        );
        this.props.LoadBusinessPointList(this.state.appId);
        if (this.state.isEdit) {
          this.toggleEdit();
        } else {
          this.toggle();
        }
      } else if (code === Result.FAILED) {
        this.showToastFailed('Failed');
        // this.toggle();
      }
    }
    if (this.props.updateActiveResult !== prevProps.updateActiveResult) {
      const code = safeDeepGet(
        this.props,
        ['updateActiveResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.showToast('Success');
        this.props.LoadBusinessPointList(this.state.appId);
        this.toggleDate();
      }
    }
    if (this.props.detailResult !== prevProps.detailResult) {
      const code = safeDeepGet(
        this.props,
        ['detailResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['detailResult', 'body'], {});
        const bpName = safeDeepGet(body, 'business_point', '');
        const device_id = safeDeepGet(body, 'device_id', '');
        const price_subs = Number(safeDeepGet(body, 'price', 0));
        this.setState({ bpName, price_subs, device_id });
        this.togglePrice();
      }
    }
    if (this.props.setPriceResult !== prevProps.setPriceResult) {
      const code = safeDeepGet(
        this.props,
        ['setPriceResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.togglePrice();
      }
    }
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      subscription: false,
      rfid: false,
      type: '',
      isEdit: false,
    }));
  }
  toggleEdit() {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
      // isEdit: !prevState.isEdit,
    }));
  }
  toggleDate() {
    this.setState(prevState => ({
      modalDate: !prevState.modalDate,
      // isEdit: !prevState.isEdit,
    }));
  }
  togglePrice() {
    this.setState(prevState => ({
      modalPrice: !prevState.modalPrice,
      // isEdit: !prevState.isEdit,
    }));
  }
  handleAddClick = () => {
    this.props.LoadBusinessPointList(this.state.appId);
    this.props.LoadBusinessPointVM(this.state.appId);
    this.toggle();
  };

  handleDateClick = row => {
    this.toggleDate();
    const idDate = safeDeepGet(row, 'device_id', safeDeepGet(row, 'id', ''));
    const date = safeDeepGet(
      this.props,
      ['readResult', 'result', 'body', idDate, 'active_date'],
      new Date().getTime()
    );
    const startDate = safeDeepGet(
      this.props,
      ['readResult', 'result', 'body', idDate, 'start_date'],
      new Date().getTime()
    );
    const endDate = safeDeepGet(
      this.props,
      ['readResult', 'result', 'body', idDate, 'end_date'],
      new Date().getTime()
    );

    const active_date = new Date(date);
    const start_date = new Date(startDate);
    const end_date = new Date(endDate);
    this.setState({ idDate, active_date, start_date, end_date });
  };
  handleGraphClick = row => {
    const device_id = safeDeepGet(row, 'device_id', '');
    this.props.history.replace(
      '/admin/business-point/' + this.state.appId + '/' + device_id
    );
  };
  handleChangePrice = row => {
    const { appId } = this.state;
    const device_id = safeDeepGet(row, 'device_id', '');
    const body = {
      appId,
      device_id,
    };
    this.props.DetailFee(body);
  };
  handleEditClick = arg => {
    const data = arg;
    console.log(data)
    this.toggleEdit();
    const sub = safeDeepGet(data, ['fee', 'daily_subscription'], null);
    const device_id = safeDeepGet(data, 'device_id', null);
    const business_point = safeDeepGet(data, 'business_point', null);
    const type = safeDeepGet(data, 'type', null);
    const tag = safeDeepGet(data, 'tags', '');
    const start_date = new Date(safeDeepGet(data, 'start_date', null));
    const end_date = new Date(safeDeepGet(data, 'end_date', null));
    const feeSubs = safeDeepGet(data, ['fee', 'daily_subscription'], 0);
    const priceRfid = safeDeepGet(data, ['fee', 'rfid'], 0);
    const minimum_monthly_threshold = safeDeepGet(
      data,
      ['fee', 'minimum_monthly_threshold'],
      0
    );
    const share_revenue =Number( ((1 - safeDeepGet(
      data,
      ['fee', 'share_revenue'],
      0
    ) )* 100).toFixed(2));
    let subscription = false;
    if (sub !== null) {
      subscription = true;
    }
    const rfid = safeDeepGet(data, ['fee', 'rfid'], null);
    let crfid = false;
    if (rfid !== null) {
      crfid = true;
    }
    console.log(device_id)
    this.setState({
      data,
      device_id,
      label: business_point,
      subscription: subscription,
      rfid: crfid,
      feeSubs,
      priceRfid,
      minimum_monthly_threshold,
      share_revenue,
      isEdit: true,
      type,
      tag,
      start_date,
      end_date,
    });
  };
  handleOkClick() {
    this.setState({ success_dlg: false });
    this.props.LoadBusinessPointList(this.state.appId);
  }
  handleDeleteClick = row => {
    const { appId } = this.state;

    const data = {
      appId,
      id: row,
    };
    this.props.DeleteBusinessPoint(data);
    this.props.LoadBusinessPointList(this.state.appId);
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Deleted',
      dynamic_description: 'Business Point has been deleted.',
    });
  };
  handleValidEdit(event, values) {
    const {
      subscription,
      rfid,
      appId,
      type,
      label,
      device_id,
      bp,
      tag,

      category,
    } = this.state;
    const tagsOptions = safeDeepGet(
      this.props,
      ['initCreateResult', 'options'],
      {}
    );
    const start_date = safeDeepGet(tagsOptions, [tag, 'start_date'], 0);
    const end_date = safeDeepGet(tagsOptions, [tag, 'end_date'], 0);
    const submittedValues = {
      ...values,
      deviceid: device_id,
      business_point: label,
      rfid: rfid,
      subscription: subscription,
      appId,
      type: type,
      category,
      tag,
      start_date: start_date,
      end_date: end_date,
    };
    this.setState({ loading: true });
    console.log(submittedValues)
    this.props.EditFee(submittedValues);
  }
  handleValidSubmit(event, values) {
    const {
      subscription,
      category,
      rfid,
      appId,
      type,
      label,
      device_id,
      bp,
      tag,
    } = this.state;
    const tagsOptions = safeDeepGet(
      this.props,
      ['initCreateResult', 'options'],
      {}
    );
    const start_date = safeDeepGet(tagsOptions, [tag, 'start_date'], 0);
    const end_date = safeDeepGet(tagsOptions, [tag, 'end_date'], 0);
    const submittedValues = {
      ...values,
      deviceid: device_id,
      business_point: bp,
      rfid: rfid,
      subscription: subscription,
      appId,
      type: type,
      tag,
      start_date: start_date,
      end_date: end_date,
    };
    this.setState({ loading: true });
    this.props.CreatedFee(submittedValues);
  }
  handleDate = active_date => {
    this.setState({ active_date: active_date[0] });
  };
  handleStartDate = start_date => {
    this.setState({ start_date: start_date[0] });
  };
  handleEndDate = end_date => {
    this.setState({ end_date: end_date[0] });
  };
  handleValidSubmitDate(event, values) {
    const submittedValues = {
      device_id: this.state.idDate,
      date: this.state.active_date.getTime(),
      startDate: this.state.start_date.getTime(),
      endDate: this.state.end_date.getTime(),
      id: this.state.appId,
    };
    this.props.UpdateActive(submittedValues);
  }
  handleValidSubmitPrice(event, values) {
    const { appId } = this.state;
    const submittedValues = {
      appId,
      device_id: this.state.device_id,
      ...values,
    };
    this.props.UpdatePrice(submittedValues);
  }
  handleChecked(e) {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (value == 'subscription') {
      if (isChecked) {
        this.setState({ subscription: true });
      } else {
        this.setState({ subscription: false });
      }
    } else if (value == 'rfid') {
      if (isChecked) {
        this.setState({ rfid: true });
      } else {
        this.setState({ rfid: false });
      }
    }
  }
  showToast(msg) {
    if (msg == 'Success') {
      var toastType = 'success';
      var message = msg;
    } else {
      var toastType = 'danger';
      var message = msg;
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed(msg) {
    var toastType = 'danger';
    var message = msg;
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    if (list.length > 0) {
      for (const el in list) {
        const data = safeDeepGet(list[el], 'data', []),
          trx_data = {
            device_id: safeDeepGet(list[el], 'device_id', '-'),
            business_point: safeDeepGet(list[el], 'name', '-'),
            jan: safeDeepGet(data[0], 'y', 0),
            feb: safeDeepGet(data[1], 'y', 0),
            mar: safeDeepGet(data[2], 'y', 0),
            apr: safeDeepGet(data[3], 'y', 0),
            mei: safeDeepGet(data[4], 'y', 0),
            jun: safeDeepGet(data[5], 'y', 0),
            jul: safeDeepGet(data[6], 'y', 0),
            agt: safeDeepGet(data[7], 'y', 0),
            sep: safeDeepGet(data[8], 'y', 0),
            oct: safeDeepGet(data[9], 'y', 0),
            nov: safeDeepGet(data[10], 'y', 0),
            dec: safeDeepGet(data[11], 'y', 0),
          };
        dataSet.push(trx_data);
      }
      return dataSet;
    }
  };
  render() {
    // TODO Loading
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const tagOption = []
    // const tagOption = safeDeepGet(this.props, ['initCreateResult', 'body'], []);
    const dataDownload = safeDeepGet(
      this.props,
      ['downloadResult', 'body'],
      []
    );
    const { device_id, idDate } = this.state;

    const vm_Option = safeDeepGet(this.props, ['vm_result', 'body'], []);
    const registered_Option = safeDeepGet(
      this.props,
      ['vm_result', 'registered'],
      []
    );
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'business_point',
        order: 'asc',
      },
    ];
    const animatedComponents = makeAnimated();

    const {
      role,
      tag,
      minimum_monthly_threshold,
      share_revenue,
      feeSubs,
      data,
      priceRfid,
      type,
      trx,
      active_date,
      start_date,
      end_date,
      category,
    } = this.state;
    const { SearchBar } = Search;
    const report_list = this.format_report(dataDownload);
    let report_filename =
      'Share_Revenue_' + this.state.labeltrx + '_' + this.state.appId;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Business Point Fee | Admin</title>
          </MetaTags>

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Business Point" breadcrumbItem="List" />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.handleOkClick()}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="device_id"
                      columns={this.state.Columns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="device_id"
                          columns={this.state.Columns}
                          data={list}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="6">
                                  <div className="search-box me-2 mb-2 d-inline-block mt-2">
                                    <div className="position-relative ">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  sm="4"
                                  className="border border-dark border-2"
                                >
                                  <Row className="mt-2">
                                    <Col sm="6">
                                      <Select
                                        onChange={this.handelChangeTrx}
                                        value={trxOptions.filter(function (
                                          option
                                        ) {
                                          return option.value === trx;
                                        })}
                                        isDisabled={
                                          list.length === 0 ? true : false
                                        }
                                        options={trxOptions}
                                      />
                                    </Col>
                                    <Col sm="6">
                                      <div className="text-sm-end">
                                        <ExcelFile
                                          filename={report_filename}
                                          element={
                                            <button
                                              type="button"
                                              className="btn btn-primary w-sm"
                                              disabled={
                                                dataDownload.length == 0
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                              Download Excel
                                            </button>
                                          }
                                        >
                                          <ExcelSheet
                                            data={report_list}
                                            name="Transaction List"
                                          >
                                            <ExcelColumn
                                              label="Device ID"
                                              value="device_id"
                                            />
                                            <ExcelColumn
                                              label="Business Point"
                                              value="business_point"
                                            />
                                            <ExcelColumn
                                              label="January"
                                              value="jan"
                                            />
                                            <ExcelColumn
                                              label="February"
                                              value="feb"
                                            />
                                            <ExcelColumn
                                              label="March"
                                              value="mar"
                                            />
                                            <ExcelColumn
                                              label="April"
                                              value="apr"
                                            />
                                            <ExcelColumn
                                              label="May"
                                              value="mei"
                                            />
                                            <ExcelColumn
                                              label="June"
                                              value="jun"
                                            />
                                            <ExcelColumn
                                              label="July"
                                              value="jul"
                                            />
                                            <ExcelColumn
                                              label="August"
                                              value="agt"
                                            />
                                            <ExcelColumn
                                              label="September"
                                              value="sep"
                                            />
                                            <ExcelColumn
                                              label="October"
                                              value="oct"
                                            />
                                            <ExcelColumn
                                              label="November"
                                              value="nov"
                                            />
                                            <ExcelColumn
                                              label="December"
                                              value="dec"
                                            />
                                          </ExcelSheet>
                                        </ExcelFile>
                                      </div>
                                    </Col>
                                  </Row>
                                  <h5 className="text-center mt-2">
                                    Share Revenue
                                  </h5>
                                </Col>
                                <Col sm="2">
                                  <div className="text-sm-end mt-2">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create Fee Business Point
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={'id'}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={'table align-middle table-nowrap'}
                                  headerWrapperClasses={'thead-light'}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={this.node}
                                />
                              </div>

                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                              {this.state.confirm_alert ? (
                                <SweetAlert
                                  title="Are you sure?"
                                  warning
                                  showCancel
                                  confirmButtonText="Yes, delete it!"
                                  confirmBtnBsStyle="success"
                                  cancelBtnBsStyle="danger"
                                  onConfirm={() =>
                                    this.handleDeleteClick(this.state.device_id)
                                  }
                                  onCancel={() =>
                                    this.setState({
                                      confirm_alert: false,
                                    })
                                  }
                                >
                                  You won&apos;t be able to revert this!
                                </SweetAlert>
                              ) : null}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modalEdit}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleEdit} tag="h4">
                        {'Edit Fee'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidEdit}>
                          <Row>
                            <Col xs="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      <Card>
                                        <CardBody>
                                          <CardTitle className="h4">
                                            Device Information
                                          </CardTitle>
                                          <p className="card-title-desc">
                                            Fill all information below
                                          </p>
                                          <Row>
                                            <FormGroup className="mb-3 select2-container">
                                              <Label>Device :</Label>
                                              <Select
                                                onChange={this.handelChangeBp}
                                                value={registered_Option.filter(
                                                  function (option) {
                                                    return (
                                                      option.value ===
                                                      safeDeepGet(
                                                        data,
                                                        'device_id'
                                                      )
                                                    );
                                                  }
                                                )}
                                                isDisabled={true}
                                                options={registered_Option}
                                              />
                                            </FormGroup>

                                            <FormGroup className="mb-3 select2-container">
                                              <Label>Type :</Label>
                                              <Select
                                                onChange={this.handelChangeType}
                                                value={typeOptions.filter(
                                                  function (option) {
                                                    return (
                                                      option.value === type
                                                    );
                                                  }
                                                )}
                                                options={typeOptions}
                                              />
                                            </FormGroup>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col sm="6">
                                      {type == 'share_revenue' ? (
                                        <Card>
                                          <CardBody>
                                            <CardTitle className="h4">
                                              Minimum Monthly Threshold
                                            </CardTitle>
                                            <p className="card-title-desc">
                                              Fill all information below
                                            </p>
                                            <Row>
                                              <Col sm="12">
                                                <FormGroup className="mb-3">
                                                  <Label htmlFor="minimum_monthly_threshold">
                                                    Monthly Threshold :
                                                  </Label>
                                                  <AvField
                                                    type="number"
                                                    id="minimum_monthly_threshold"
                                                    name="minimum_monthly_threshold"
                                                    value={
                                                      minimum_monthly_threshold ||
                                                      1000000
                                                    }
                                                    placeholder="Masukan harga Subscription..."
                                                    className="form-control"
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col sm="12">
                                                <FormGroup className="mb-3">
                                                  <Label htmlFor="share_revenue">
                                                    Share Revenue (%) :
                                                  </Label>
                                                  <AvField
                                                    type="number"
                                                    id="share_revenue"
                                                    name="share_revenue"
                                                    value={
                                                      share_revenue ||
                                                      7.5
                                                    }
                                                    placeholder="Masukan Persentase Share Revenue dalam Persen 10% tuliskan 10."
                                                    className="form-control"
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      ) : // type == 'subscription' ? (
                                      //   <>
                                      //     <Card>
                                      //       <CardBody>
                                      //         <CardTitle className="h4">
                                      //           Subscription Information
                                      //         </CardTitle>
                                      //         <p className="card-title-desc">
                                      //           Fill all information below
                                      //         </p>
                                      //         <Row>
                                      //           <Col sm="12">
                                      //             <FormGroup className="mb-3">
                                      //               <Label htmlFor="feeSubs">
                                      //                 Fee Subscription :
                                      //               </Label>
                                      //               <AvField
                                      //                 type="text"
                                      //                 id="feeSubs"
                                      //                 name="feeSubs"
                                      //                 value={feeSubs}
                                      //                 placeholder="Masukan harga Subscription..."
                                      //                 className="form-control"
                                      //               />
                                      //             </FormGroup>
                                      //           </Col>
                                      //         </Row>
                                      //       </CardBody>
                                      //     </Card>
                                      //     <Card>
                                      //       <CardBody>
                                      //         <CardTitle className="h4">
                                      //           RFID Information
                                      //         </CardTitle>
                                      //         <p className="card-title-desc">
                                      //           Fill all information below
                                      //         </p>
                                      //         <Row>
                                      //           <Col sm="12">
                                      //             <FormGroup className="mb-3">
                                      //               <Label htmlFor="priceRfid">
                                      //                 Fee :
                                      //               </Label>
                                      //               <AvField
                                      //                 type="number"
                                      //                 id="priceRfid"
                                      //                 name="priceRfid"
                                      //                 value={priceRfid}
                                      //                 placeholder="Masukan harga rfid..."
                                      //                 className="form-control"
                                      //               />
                                      //             </FormGroup>
                                      //           </Col>
                                      //           <Col sm="6"></Col>
                                      //         </Row>
                                      //       </CardBody>
                                      //     </Card>
                                      //   </>
                                      // ) :
                                      type == 'subscription' ? (
                                        <Card>
                                          <CardBody>
                                            <CardTitle className="h4">
                                              Subscription Information
                                            </CardTitle>
                                            <p className="card-title-desc">
                                              Fill all information below
                                            </p>
                                            <Row>
                                              <Col sm="12">
                                                <FormGroup className="mb-3 select2-container">
                                                  <Label>Tags :</Label>
                                                  <Select
                                                    onChange={
                                                      this.handelChangeTag
                                                    }
                                                    // isMulti
                                                    defaultValue={JSON.stringify(
                                                      tag
                                                    )}
                                                    value={tagOption.filter(
                                                      function (option) {
                                                        return (
                                                          option.value === tag
                                                        );
                                                      }
                                                    )}
                                                    options={tagOption}
                                                    isLoading={false}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>

                              <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                <Link
                                  className="btn btn-secondary"
                                  role="button"
                                  onClick={this.toggleEdit}
                                  to="#"
                                >
                                  Batal
                                </Link>{' '}
                                <Button type="submit" color="primary">
                                  Simpan
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                    <Modal
                      size="lg"
                      isOpen={this.state.modalDate}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleDate} tag="h4">
                        {'Active Date Business Point'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmitDate}>
                          <Row>
                            <Col xs="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Start Date</Label>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            maxDate: 'today',
                                            // enableTime: true,
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={start_date}
                                          onChange={this.handleStartDate}
                                          ref={this.refDatePicker}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>End Date</Label>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            // maxDate: 'today',
                                            // enableTime: true,
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={end_date}
                                          onChange={this.handleEndDate}
                                          ref={this.refDatePicker}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="6">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Active Date</Label>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            maxDate: 'today',
                                            enableTime: true,
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={active_date}
                                          onChange={this.handleDate}
                                          ref={this.refDatePicker}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                <Link
                                  className="btn btn-secondary"
                                  role="button"
                                  onClick={this.toggleDate}
                                  to="#"
                                >
                                  Batal
                                </Link>{' '}
                                <Button type="submit" color="primary">
                                  Simpan
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Create Fee Business Point'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <Row>
                            <Col xs="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      <Card>
                                        <CardBody>
                                          <CardTitle className="h4">
                                            Device Information
                                          </CardTitle>
                                          <p className="card-title-desc">
                                            Fill all information below
                                          </p>
                                          <Row>
                                            <FormGroup className="mb-3 select2-container">
                                              <Label>Device :</Label>
                                              <Select
                                                isMulti
                                                onChange={this.handelChangeBp}
                                                components={animatedComponents}
                                                closeMenuOnSelect={false}
                                                value={vm_Option.filter(function (
                                                  option
                                                ) {
                                                  return option.value === device_id;
                                                })}
                                                options={vm_Option}
                                              />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                              <FormGroup className="mb-3 select2-container">
                                                <Label>Type :</Label>
                                                <Select
                                                  onChange={
                                                    this.handelChangeType
                                                  }
                                                  value={typeOptions.filter(
                                                    function (option) {
                                                      return (
                                                        option.value === type
                                                      );
                                                    }
                                                  )}
                                                  options={typeOptions}
                                                />
                                              </FormGroup>
                                            </FormGroup>
                                            <FormGroup className="mb-3"></FormGroup>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col sm="6">
                                      {type == 'share_revenue' ? (
                                        <Card>
                                          <CardBody>
                                            <CardTitle className="h4">
                                              Minimum Monthly Threshold
                                            </CardTitle>
                                            <p className="card-title-desc">
                                              Fill all information below
                                            </p>
                                            <Row>
                                              <Col sm="12">
                                                <FormGroup className="mb-3">
                                                  <Label htmlFor="minimum_monthly_threshold">
                                                    Monthly Threshold :
                                                  </Label>
                                                  <AvField
                                                    type="text"
                                                    id="minimum_monthly_threshold"
                                                    name="minimum_monthly_threshold"
                                                    value={
                                                      minimum_monthly_threshold ||
                                                      1000000
                                                    }
                                                    placeholder="Masukan harga Subscription..."
                                                    className="form-control"
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col sm="12">
                                                <FormGroup className="mb-3">
                                                  <Label htmlFor="share_revenue">
                                                  Share Revenue (%) :
                                                  </Label>
                                                  <AvField
                                                    type="number"
                                                    id="share_revenue"
                                                    name="share_revenue"
                                                    value={
                                                      share_revenue ||
                                                      7.5
                                                    }
                                                    placeholder="Masukan Persentase Share Revenue dalam Persen 10% tuliskan 10."
                                                    className="form-control"
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      ) : // type == 'subscription' ? (
                                      //   <>
                                      //     <Card>
                                      //       <CardBody>
                                      //         <CardTitle className="h4">
                                      //           Subscription Information
                                      //         </CardTitle>
                                      //         <p className="card-title-desc">
                                      //           Fill all information below
                                      //         </p>
                                      //         <Row>
                                      //           <Col sm="12">
                                      //             <FormGroup className="mb-3">
                                      //               <Label htmlFor="feeSubs">
                                      //                 Fee Subscription :
                                      //               </Label>
                                      //               <AvField
                                      //                 type="text"
                                      //                 id="feeSubs"
                                      //                 name="feeSubs"
                                      //                 value={feeSubs}
                                      //                 placeholder="Masukan harga Subscription..."
                                      //                 className="form-control"
                                      //               />
                                      //             </FormGroup>
                                      //           </Col>
                                      //         </Row>
                                      //       </CardBody>
                                      //     </Card>
                                      //     <Card>
                                      //       <CardBody>
                                      //         <CardTitle className="h4">
                                      //           RFID Information
                                      //         </CardTitle>
                                      //         <p className="card-title-desc">
                                      //           Fill all information below
                                      //         </p>
                                      //         <Row>
                                      //           <Col sm="12">
                                      //             <FormGroup className="mb-3">
                                      //               <Label htmlFor="priceRfid">
                                      //                 Fee :
                                      //               </Label>
                                      //               <AvField
                                      //                 type="number"
                                      //                 id="priceRfid"
                                      //                 name="priceRfid"
                                      //                 value={priceRfid}
                                      //                 placeholder="Masukan harga rfid..."
                                      //                 className="form-control"
                                      //               />
                                      //             </FormGroup>
                                      //           </Col>
                                      //           <Col sm="6"></Col>
                                      //         </Row>
                                      //       </CardBody>
                                      //     </Card>
                                      //   </>
                                      // ) :
                                      type == 'subscription' ? (
                                        <Card>
                                          <CardBody>
                                            <CardTitle className="h4">
                                              Subscription Information
                                            </CardTitle>
                                            <p className="card-title-desc">
                                              Fill all information below
                                            </p>
                                            <Row>
                                              <Col sm="12">
                                                <FormGroup className="mb-3 select2-container">
                                                  <Label>Tags :</Label>
                                                  <Select
                                                    onChange={
                                                      this.handelChangeTag
                                                    }
                                                    // isMulti
                                                    defaultValue={JSON.stringify(
                                                      tag
                                                    )}
                                                    value={tagOption.filter(
                                                      function (option) {
                                                        return (
                                                          option.value === tag
                                                        );
                                                      }
                                                    )}
                                                    options={tagOption}
                                                    isLoading={false}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>

                              <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                <Link
                                  className="btn btn-secondary"
                                  role="button"
                                  onClick={this.toggle}
                                  to="#"
                                >
                                  Batal
                                </Link>{' '}
                                <Button
                                  type="submit"
                                  color="primary"
                                  disabled={this.state.loading}
                                >
                                  Simpan
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                    {/* Modal Price */}
                    <Modal
                      size="lg"
                      isOpen={this.state.modalPrice}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.togglePrice} tag="h4">
                        {'Subscription Price ' + this.state.bpName}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmitPrice}>
                          <Row>
                            <Col xs="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="12">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Price Subscription:</Label>
                                        <AvField
                                          type="number"
                                          id="price_subs"
                                          name="price_subs"
                                          value={
                                            this.state.price_subs || 1000000
                                          }
                                          placeholder="Masukan harga Subscription..."
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                <Link
                                  className="btn btn-secondary"
                                  role="button"
                                  onClick={this.togglePrice}
                                  to="#"
                                >
                                  Batal
                                </Link>{' '}
                                <Button type="submit" color="primary">
                                  Simpan
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['BpFee', 'list'], {}),
  initCreateResult: safeDeepGet(state, ['BpFee', 'initCreate'], {}),
  vm_result: safeDeepGet(state, ['BpFee', 'list_vm'], {}),
  createResult: safeDeepGet(state, ['BpFee', 'create'], {}),
  editResult: safeDeepGet(state, ['BpFee', 'edit'], {}),
  downloadResult: safeDeepGet(state, ['BpFee', 'download'], {}),
  loading: safeDeepGet(state, ['BpFee', 'loading'], true),
  detailResult: safeDeepGet(state, ['BpFee', 'detail'], true),
  setPriceResult: safeDeepGet(state, ['BpFee', 'set_price_subs'], true),
  readResult: safeDeepGet(state, ['businessPoint', 'readInfo'], {}),
  updateActiveResult: safeDeepGet(
    state,
    ['businessPoint', 'update_time', 'result'],
    {}
  ),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadBusinessPointList: appId => dispatch(list(appId)),
  LoadBusinessPointVM: appId => dispatch(list_vm(appId)),
  LoadBusinessPointFillter: code => dispatch(list(code)),
  DeleteBusinessPoint: payload => dispatch(delete_(payload)),
  Download: payload => dispatch(download(payload)),
  CreatedFee: payload => dispatch(create(payload)),
  EditFee: payload => dispatch(edit(payload)),
  DetailFee: payload => dispatch(detail(payload)),
  ReadInfoBp: payload => dispatch(readInfo(payload)),
  UpdateActive: payload => dispatch(update_time(payload)),
  InitCreate: appId => dispatch(initCreate(appId)),
  UpdatePrice: payload => dispatch(set_price_subs(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPoint);
